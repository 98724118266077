import React from 'react';
import IFirstCompilationProps from "./IFirstCompilationProps";
import IFirstCompilationState from './IFirstCompilationState';
import FirstCompilationState from "./FirstCompilationState.json";
import "./FirstCompilation.css";
import Header from "../Header/Header";
import CodiceFiscale from "codice-fiscale-js";
import wLazyForm from "@en/wlazyform";
import {ApiService} from "../../libs/ApiService";
import App from "../../App";
import transformMRZData from "../../libs/mrzDateConverter";
import IKeycloakToken from "../../libs/IKeycloakToken";
import CompilingPageState from "../CompilingPage/CompilingPageState.json";


export default class FirstCompilation extends React.Component<IFirstCompilationProps, IFirstCompilationState> {

  private templateToCompile:string="";
  private language:string="";
  private company:string="";
  private template:string="";


  constructor(props: any) {
    super(props);
    this.state = FirstCompilationState;
  }

  componentDidMount(): void {
    document.title="Compilazione template-wDocs"
    try{
      this.templateToCompile=atob(window.location.search.split("template=")[1]);
    }
    catch(e){
      console.error(e);
    }
  }

  componentWillUnmount(): void {
    document.title="wDocs"
  }
  render() {

    console.log("Entrato nel render");
    return (
        <div>
          <Header logout={() => {}}></Header>
          <div className="scrollable-content mt-5 pt-3">
            {this.state.onScreen === "cfInsertion" && (
                <div>
                  <div className="main-content pt-3">
                    <div className="form-container">
                      <h2 className="mb-3 text-center big-title">Inserire il codice fiscale dell'utente a cui associare
                        la
                        pratica</h2>
                      <form onSubmit={(e) => {
                        e.preventDefault();
                        this.sendUser(e)
                      }} id="cfInsertForm">
                        <input type="text" className="form-control big-input" id="codiceFiscale"
                               placeholder="Codice Fiscale" minLength={16}
                               maxLength={16} onChange={() => {
                          this.checkCF()
                        }} required/>
                      </form>
                    </div>
                  </div>
                  <div className="fixed-bottom footer-content">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col text-start">
                        </div>
                        <div className="col text-end">
                          <button className="btn btn-primary" disabled={this.state.disabledSendButton}
                                  onClick={() => {
                                    this.showOverlay();
                                    this.sendUser();
                                  }}>Invia
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            )}
            {this.state.onScreen == "endingCompilation" && (
                <div>
                  <div>
                    <h3>Grazie. La compilazione è stata completata e salvata con successo.</h3>
                    <h4>Puoi chiudere questa schermata.</h4>
                  </div>
                </div>
            )}
            {this.state.onScreen == "templateCompilation" && (
                <div>
                  <div className="fixed-bottom footer-content">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col text-start">
                          <button className="btn btn-secondary" onClick={(a) => {
                            this.cancelCompilation()
                          }}>Annulla
                          </button>
                        </div>
                        <div className="col text-end">
                          <button className="btn btn-primary" id="compilingFormBtn" onClick={() => {
                            document.getElementById("btnSendForm")?.click();
                          }}>Invia
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            )}
            {this.state.onScreen === "waitingForServer" && (
                <div id="overlay">
                  <div id="overlay-content">
                    <div className="loader"></div>
                    <p className="text-light mt-3 fs-4">Caricamento...</p>
                  </div>
                </div>)}
            {(this.state.onScreen === "cfInsertion" || this.state.onScreen==="templateCompilation") && (
                <div id="generatedFormPlaceholder">

                </div>)}
          </div>
        </div>

    )
  }

  private async sendUser(e?: React.FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    //this.showOverlay();
    const config=await App.getConfig();
    const userRes:{
      isUserIdentified:boolean,
      user:{
        name:string,
        surname:string,
        fiscalCode:string,
        birthDate:any,
        email:string,
        phone:any,
        birthCity:string,
        birthProvince:string,
        birthCountry:string,
        sex:string,
        AESAcceptDate:any
      },
      hasUserAlreadySignedFEA:boolean
    }=await ApiService.callToServer("anagrafica","getUserFromCF","v1",{
      CF:this.state.taxCode,
      CompanyName:config.MyExpress.Context.Tenant!="@DEFAULT"?config.MyExpress.Context.Tenant:"Euronovate"
    });
    FirstCompilationState.onScreen="templateCompilation";
    this.setState(FirstCompilationState);
    if(userRes.isUserIdentified){
      document.getElementById("generatedFormPlaceholder")?.addEventListener('DOMNodeInserted',()=>{
        if(document.getElementById("generatedForm")){
          if((document.getElementById("surname") as HTMLInputElement)){
            (document.getElementById("surname") as HTMLInputElement).value=userRes.user.surname;
          }
          if((document.getElementById("name") as HTMLInputElement)){
            (document.getElementById("name") as HTMLInputElement).value=userRes.user.name;
          }
          if((document.getElementById("dateOfBirth") as HTMLInputElement)){
            (document.getElementById("dateOfBirth") as HTMLInputElement).value=userRes.user.birthDate;
          }
          if((document.getElementById("fiscalCode") as HTMLInputElement)){
            (document.getElementById("fiscalCode") as HTMLInputElement).value=userRes.user.fiscalCode;
          }
        }
      });
    }
    let garbageInfo="";
    [this.language,this.company,this.template,garbageInfo]=this.templateToCompile.split('/');
    await wLazyForm.getForm(this.language,this.company,this.template,false,false,true,true,'wLazyDefaultAccess','asdghjkl').then(async (res) => {
      await ApiService.callToServer("documenti","saveUserCompilation","v1",{
        CompanyName:config.MyExpress.Context.Tenant!="@DEFAULT"?config.MyExpress.Context.Tenant:"Euronovate",
        compiledData:res,
        TemplateID:this.templateToCompile,
        TaxCode:this.state.taxCode,
        State:"COMPILED"
      }).catch((e)=>{console.error(e)});
          FirstCompilationState.onScreen = "endingCompilation";
      this.setState(FirstCompilationState);
    }).catch((err)=>{console.log(err)});
  }

  private showOverlay() {
    FirstCompilationState.onScreen="waitingForServer";
    this.setState(FirstCompilationState);
  }

  private checkCF() {
    let CFInputElement=document.getElementById("codiceFiscale");
    if(CFInputElement){
      let CF=(CFInputElement as HTMLInputElement).value;
      if(CF.length===16){
        if(CodiceFiscale.check(CF)){
          FirstCompilationState.taxCode=CF;
          FirstCompilationState.disabledSendButton=false;
        }
      }
      else{
        if(!this.state.disabledSendButton){
          FirstCompilationState.disabledSendButton=true;
        }
      }
      this.setState(FirstCompilationState);
    }
  }

  private cancelCompilation() {
    const formPlaceholder=document.getElementById("generatedFormPlaceholder")
    if(formPlaceholder){
      formPlaceholder.innerHTML="";
    }
    FirstCompilationState.onScreen="cfInsertion";
    this.setState(FirstCompilationState);
  }
}

