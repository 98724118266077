import React from 'react';
import Keycloak from 'keycloak-js';
import Content from './components/Content/Content';
import 'bootstrap/dist/js/bootstrap.bundle'
import { Configuration } from './config/Configuration';
import appConfig from "./config/Configuration.json";
import IKeycloakToken from './libs/IKeycloakToken';
import {ApiService} from "./libs/ApiService";
import ErrorComponent from "./wDocs/ErrorComponent/ErrorComponent";
import FirstCompilation from "./wDocs/FirstCompilationPage/FirstCompilation";
import wLazyForm from "@en/wlazyform";

interface AppState {
  isAuthenticated: boolean;
  isCorrectTenant:boolean;
  keycloakToken?: IKeycloakToken;
  keycloak: Keycloak.KeycloakInstance | null;
  onlyapp:boolean;
  onlyappSelfie:boolean
}

export default class App extends React.Component<{}, AppState> {
  
  state: AppState = {
    keycloak: null,
    isAuthenticated: false,
    isCorrectTenant:false,
    keycloakToken: undefined,
    onlyapp:false,
    onlyappSelfie:false
  };

  public static keycloak:Keycloak|null=null;
  public static keycloakUser:string="";
  public static companyName:string="Euronovate";
  public static vertical:string="TEST";
  public static environment:string="dev";

  private currentContent = React.createRef<Content>();
  private currentSidebarDiv = React.createRef<HTMLDivElement>();
  // private homeUrl: string = "";
  private clickToShowMenu: boolean = false;
  private static isCompilationGoing:boolean=false;

  constructor(props: any) {
      super(props);
      // this.state.isAuthenticated = false;
      Configuration.Load(appConfig);
      console.log("Costruttore eseguito");
    const dynamicServerURL:string="https://"+window.location.hostname+"/backend"
    const dynamicWLazyFormURL:string="https://"+window.location.hostname+"/wlazyform"
    ApiService.setServerURL(process.env.REACT_APP_BACKEND_ENDPOINT?process.env.REACT_APP_BACKEND_ENDPOINT:dynamicServerURL);
    wLazyForm.Init(process.env.REACT_APP_WLAZYFORM_ENDPOINT?process.env.REACT_APP_WLAZYFORM_ENDPOINT:dynamicWLazyFormURL);

  }

  async componentDidMount() {
    if(!App.isCompilationGoing){
      document.title="wDocs"
      let config=await App.getConfig();
      console.log(config);
      const keycloak = new Keycloak({
        realm:config.MyExpress.Auth.Keycloak.realm,
        clientId:config.MyExpress.Auth.Keycloak.clientId,
        url:config.MyExpress.ClientAuth.keycloakBaseUrl
      });
      const authenticated = await keycloak.init({ onLoad: 'login-required' }).catch(()=>{console.error("Error while reaching for keycloak")});
      App.keycloak=keycloak;
      App.keycloakUser=(keycloak.tokenParsed as IKeycloakToken).preferred_username.split('@')[0];
      App.companyName=config.MyExpress.Context.Tenant!="@DEFAULT"?config.MyExpress.Context.Tenant:"Euronovate";
      App.vertical=config.Vertical.Name!=""?config.Vertical.Name:"TEST";
      App.environment=config.MyExpress.Context.Environment;
      let companyFromDomain=App.environment=="live"?await ApiService.callToServer("anagrafica","getCompanyByDomain","v1",{
        "CompanyDomain":(keycloak.tokenParsed as IKeycloakToken).preferred_username.split('@')[1]
      }).catch(()=>{console.error("Not a correct tenant!")}):"Placeholder Company"
      this.setState({
        keycloak,
        isAuthenticated: authenticated?authenticated:false,
        isCorrectTenant:companyFromDomain?true:false,
        keycloakToken: keycloak.tokenParsed as IKeycloakToken // Dettagli dell'utente
      });
    }


  }

  public static handleLogout = () => {
    App.keycloak?.logout();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  // Creazione del Context
  private sideBarChange(value: string) {
    this.currentContent.current?.SetNewValue(value);
    if (this.clickToShowMenu) {
      const node: HTMLDivElement | null= this.currentSidebarDiv.current;
      node?.classList.add('d-none');
      node?.classList.add('d-md-block');
      this.clickToShowMenu = false;
    }
  }
  handleResize = () => {
    const node: HTMLDivElement | null= this.currentSidebarDiv.current;
    if (!node?.classList.contains('d-none')) {
      node?.classList.add('d-none');
      node?.classList.add('d-md-block');
    }
  };

  static async getConfig(){
    let config:{
      "MyExpress": {
        "Context": {
          "Environment": string,
          "Product": string,
          "Tenant": string,
          "DomainLength": 2
        },
        "Auth": {
          "Keycloak": {
            "keycloakBaseUrl": string,
            "realm": string,
            "clientId": string
          }
        },
        "ClientAuth": {
          "keycloakBaseUrl": string,
          "realm": string,
          "clientId": string
        },
        "Node": {
          "Port": 0,
          "Limit":0
        },
        "Path": {
          "Log": string,
          "Service": string,
          "Config": string
        }
      },
      "Vertical": {
        "Name": string
      },
      "ClientAuth": {
        "keycloakBaseUrl": string,
        "realm": string,
        "clientId": string
      },
      "Database_AESIdentity":{
        "User": string,
        "Password": string,
        "Url": string,
        "Name": string,
        "Schema": string
      },
      "Database_Documents":{
        "User": string,
        "Password": string,
        "Url": string,
        "Name": string,
        "Schema": string
      },
      "Path": {
        "DbResource": string,
        "ResourceName": string
      }
    } = await ApiService.callToServer("utils", "getConfiguration", "v1").catch(()=>{});
    return config;
  }

  render() {
    if(window.location.pathname=="/compilation"){
      App.isCompilationGoing=true;
      return (
          <div className="App">
            <div>
              <FirstCompilation></FirstCompilation>
            </div>
          </div>
      );
    }
    else {
      if (this.state.keycloak) {
        if (this.state.isAuthenticated) {
          if (this.state.isCorrectTenant) {
            console.log(this.state.keycloakToken?.realm_access.roles)
            return (
                <div className="App">
                  <div>
                    {/* Assumi che Content prenda il nome dell'utente come prop */}
                    <Content ref={this.currentContent} name={this.state.keycloakToken?.given_name}/>
                  </div>
                </div>
            );
          } else {
            return (
                <div className="App">
                  <div>
                    <ErrorComponent></ErrorComponent>
                  </div>
                </div>
            );
          }
        } else {
          return <div>Unable to authenticate!</div>;
        }
      }
    }
    return <div>Loading...</div>;
  }

}

